import React from 'react';
import {PlusCircleIcon} from "lucide-react";

const DeliveryNote = () => {
    return (
        <React.Fragment>
            <div className="card my-4">
                <div className="card-body">
                    <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-3 mb-4">
                        <h6 className="uppercase font-light">Transactions</h6>
                        <button className="btn-primary flex items-center gap-1">
                            <PlusCircleIcon size={18}/>
                            Add Sale Order
                        </button>
                    </div>
                    <div className="overflow-x-auto my-3 text-sm">
                        <table>
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Party</th>
                                <th>Note No</th>
                                <th>Due Date</th>
                                <th>Total Amount</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default DeliveryNote;