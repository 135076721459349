import React from 'react';
import {Dropdown} from "../../../Common/Components/Dropdown";
import {BuildingIcon, ChevronDown} from "lucide-react";

const BankAccounts = () => {
    return (
        <React.Fragment>
            <div className="grid grid-cols-3 gap-3 my-4">
                <div className="col-span-1 card">
                    <div className="card-body">
                        <div className="flex gap-1 justify-between items-center">
                            <h6 className="text-15 font-semibold">Accounts</h6>
                            <button className="btn-primary">Add Bank</button>
                        </div>
                        <div className="overflow-x-auto my-3 text-sm">
                            <table className="w-full">
                                <thead className="ltr:text-left rtl:text-right">
                                <tr>
                                    <th>Acc Name</th>
                                    <th className="text-right">Amount</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <div className="flex justify-start items-center">
                                            <BuildingIcon size={18} />
                                            DIB
                                        </div>
                                    </td>
                                    <td className="text-right">$4,521</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-span-2 flex flex-col gap-2 justify-start items-start">
                    <div className="card w-full">
                        <div className="card-body">
                            <div className="flex justify-between items-start gap-3 flex-col md:flex-row">
                                <div className="flex flex-col gap-1 justify-start items-start">
                                    <span>Bank Name: </span>
                                    <span>Account Number: </span>
                                    <span>Swift Code: </span>
                                    <span>UP ID: </span>
                                </div>
                                <div className="flex flex-col gap-1 justify-end items-end">
                                    <Dropdown className="relative">
                                        <Dropdown.Trigger type="button" className="btn-primary" id="dropdownMenuButton" data-bs-toggle="dropdown">
                                            Deposit/Withdrawal <ChevronDown className="inline-block size-4 ltr:ml-1 rtl:mr-1"></ChevronDown>
                                        </Dropdown.Trigger>

                                        <Dropdown.Content placement="right-end" as="ul"
                                                          className="absolute z-50 py-2 mt-1 list-none bg-white rounded-md shadow-md ltr:text-left rtl:text-right dropdown-menu min-w-max dark:bg-zink-600"
                                                          aria-labelledby="dropdownMenuButton">
                                            <li>
                                                <a className="block px-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                                                   href="#!">
                                                    Bank To Cash Transfer
                                                </a>
                                            </li>
                                            <li>
                                                <a className="block px-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                                                   href="#!">
                                                    Cash To Bank Transfer
                                                </a>
                                            </li>
                                            <li>
                                                <a className="block px-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                                                   href="#!">
                                                    Bank To Bank Transfer
                                                </a>
                                            </li>
                                            <li>
                                                <a className="block px-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                                                   href="#!">
                                                    Adjust Bank Balance
                                                </a>
                                            </li>
                                        </Dropdown.Content>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card w-full">
                        <div className="card-body">
                            <h6 className="font-bold uppercase">Transactions</h6>
                            <div className="overflow-x-auto my-3 text-sm">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Type</th>
                                        <th>Number</th>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default BankAccounts;