import React from 'react';
import {Link, useLocation} from "react-router-dom";

const SaleOrderLayout = (props:any) => {
    const location = useLocation();

    return (
        <React.Fragment>
            <div className="my-4">
                <div className="flex flex-grow-1 justify-between gap-1">
                    <Link to="/sale/order/list" className={`${location.pathname==='/sale/order/list' ? 'btn-primary' : 'btn-secondary'} w-full`}>Sale Order</Link>
                    <Link to="/sale/order/online" className={`${location.pathname==='/sale/order/online' ? 'btn-primary' : 'btn-secondary'} w-full`}>Online Order</Link>
                </div>
            </div>
            {props.children}
        </React.Fragment>
    );
};

export default SaleOrderLayout;