import React from 'react';
import SaleOrderLayout from "./SaleOrderLayout";

const OnlineOrder = () => {
    return (
        <SaleOrderLayout>
            <div>Online Order</div>
        </SaleOrderLayout>
    );
};

export default OnlineOrder;