import {
    ArchiveRestoreIcon,
    Award, BanknoteIcon, BarChart, CalculatorIcon,
    CalendarDays,
    CircuitBoard,
    Codesandbox,
    FileText, FullscreenIcon,
    HomeIcon,
    LifeBuoy, ListChecksIcon, ListIcon,
    LocateFixed,
    Mail,
    Map,
    MessageSquare,
    MonitorDot,
    PackagePlus, PenToolIcon,
    PictureInPicture2,
    PieChart,
    RadioTower, RefreshCwIcon,
    ScrollText, Settings2Icon, SettingsIcon,
    Share2,
    ShoppingBag, ShoppingBasketIcon, StoreIcon,
    Table,
    Trophy,
    UserRound, UsersIcon, Wallet2Icon
} from "lucide-react";

const menuData: any = [
    {
        label: 'menu',
        isTitle: true,
    },
    {
        id: 'home',
        label: 'Home',
        icon: <HomeIcon/>,
        link: '/home',
        parentId: 2
    },
    {
        id: 'parties',
        label: 'Parties',
        icon: <UsersIcon/>,
        link: '/parties',
        parentId: 2
    },
    {
        id: 'items',
        label: 'Items',
        icon: <ListIcon/>,
        link: '/items/list',
        parentId: 2
    },
    {
        id: "sale",
        label: 'Sale',
        link: "/#",
        icon: <ListChecksIcon/>,
        subItems: [
            {
                id: 'saleInvoices',
                label: 'Sale Invoices',
                link: '/sale/sale-invoices',
                parentId: "sale"
            },
            {
                id: 'estimateQuotation',
                label: 'Estimate/Quotation',
                link: '/sale/estimation-quotation',
                parentId: "sale"
            },
            {
                id: 'paymentIn',
                label: 'Payment In',
                link: '/sale/payment-in',
                parentId: "sale"
            },
            {
                id: 'saleOrder',
                label: 'Sale Order',
                link: '/sale/order/list',
                parentId: "sale"
            },
            {
                id: 'deliveryNote',
                label: 'Delivery Note',
                link: '/sale/delivery-note',
                parentId: "sale"
            },
            {
                id: 'saleReturn',
                label: 'Sale Return/Cr.Note',
                link: '/sale/sale-return',
                parentId: "sale"
            },
        ]
    },
    {
        id: "purchase",
        label: 'Purchase',
        link: "/#",
        icon: <ShoppingBasketIcon/>,
        subItems: [
            {
                id: 'purchaseBills',
                label: 'Purchase Bills',
                link: '/purchase/bills',
                parentId: "purchase"
            },
            {
                id: 'paymentOut',
                label: 'Payment Out',
                link: '/purchase/payment-out',
                parentId: "purchase"
            },
            {
                id: 'purchaseOrder',
                label: 'Purchase Order',
                link: '/purchase/order',
                parentId: "purchase"
            },
            {
                id: 'purchaseReturn',
                label: 'Purchase Return/Dr.Note',
                link: '/purchase/return',
                parentId: "purchase"
            },
        ]
    },
    {
        id: 'quickBilling',
        label: 'Quick Billing',
        icon: <CalculatorIcon/>,
        link: '/quick-billing',
        parentId: 2
    },
    {
        id: 'expenses',
        label: 'Expenses',
        icon: <Wallet2Icon/>,
        link: '/expenses/category',
        parentId: 2
    },
    {
        id: "cashAndBank",
        label: 'Cash & Bank',
        link: "/#",
        icon: <BanknoteIcon/>,
        subItems: [
            {
                id: 'bankAccounts',
                label: 'Bank Accounts',
                link: '/cash-bank/accounts',
                parentId: "cashAndBank"
            },
            {
                id: 'cashInHand',
                label: 'Cash In Hand',
                link: '/cash-bank/cash-in-hand',
                parentId: "cashAndBank"
            },
            {
                id: 'cheques',
                label: 'Cheques',
                link: '/cash-bank/cheques',
                parentId: "cashAndBank"
            },
            {
                id: 'loanAccounts',
                label: 'Loan Accounts',
                link: '/cash-bank/loan-accounts',
                parentId: "cashAndBank"
            },
        ]
    },
    {
        id: 'myOnlineStore',
        label: 'My Online Store',
        icon: <StoreIcon/>,
        link: '/my-online-store',
        parentId: 2
    },
    {
        id: 'reports',
        label: 'Reports',
        icon: <BarChart/>,
        link: '/reports',
        parentId: 2
    },
    {
        label: 'settings',
        isTitle: true,
    },
    {
        id: 'syncAndShare',
        label: 'Sync & Share',
        icon: <RefreshCwIcon/>,
        link: '/sync-and-share',
        parentId: 2
    },
    {
        id: "backupAndRestore",
        label: 'Backup & Restore',
        link: "/#",
        icon: <ArchiveRestoreIcon/>,
        subItems: [
            {
                id: 'autoBackup',
                label: 'Auto Backup',
                link: '/auto-backup',
                parentId: "backupAndRestore"
            },
            {
                id: 'backupToComputer',
                label: 'Backup to Computer',
                link: '/backup-to-computer',
                parentId: "backupAndRestore"
            },
            {
                id: 'backupToDrive',
                label: 'Backup to Drive',
                link: '/backup-to-drive',
                parentId: "backupAndRestore"
            },
            {
                id: 'restoreBackup',
                label: 'Restore Backup',
                link: '/restore-backup',
                parentId: "backupAndRestore"
            },
        ]
    },
    {
        id: "utilities",
        label: 'Utilities',
        link: "/#",
        icon: <Settings2Icon/>,
        subItems: [
            {
                id: 'importItems',
                label: 'Import Items',
                link: '/import-items',
                parentId: "utilities"
            },
            {
                id: 'bulkUpdateItems',
                label: 'Bulk Update Items',
                link: '/bulk-update-items',
                parentId: "utilities"
            },
            {
                id: 'importParties',
                label: 'Import Parties',
                link: '/import-parties',
                parentId: "utilities"
            },
            {
                id: 'exportToTally',
                label: 'Export to Tally',
                link: '/export-to-tally',
                parentId: "utilities"
            },
            {
                id: 'exportItems',
                label: 'Export Items',
                link: '/export-items',
                parentId: "utilities"
            },
            {
                id: 'verifyMyData',
                label: 'Verify My Data',
                link: '/verify-my-data',
                parentId: "utilities"
            },
            {
                id: 'recycleBin',
                label: 'Recycle Bin',
                link: '/recycle-bin',
                parentId: "utilities"
            },
            {
                id: 'closeFinancialYear',
                label: 'Close Financial Year',
                link: '/close-financial-year',
                parentId: "utilities"
            },
        ]
    },
    {
        id: 'settings',
        label: 'Settings',
        icon: <SettingsIcon/>,
        link: '/settings',
        parentId: 2
    },
    {
        label: 'extras',
        isTitle: true,
    },
    {
        id: 'requestADemo',
        label: 'Request a Demo',
        icon: <FullscreenIcon/>,
        link: '/request-a-demo',
        parentId: 2
    },
];

export {menuData};