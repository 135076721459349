import React from 'react';
import ItemLayout from "./ItemLayout";
import {ForwardIcon} from "lucide-react";

const Services = () => {
    return (
        <ItemLayout>
            <div className="grid grid-cols-3 gap-3 my-4">
                <div className="col-span-1 card">
                    <div className="card-body">
                        <div className="flex gap-1 justify-between items-center">
                            <h6 className="text-15 font-semibold">Services</h6>
                            <button className="btn-primary">Add New</button>
                        </div>
                        <div className="overflow-x-auto my-3 text-sm">
                            <table className="w-full">
                                <thead className="ltr:text-left rtl:text-right">
                                <tr>
                                    <th>Service</th>
                                    {/*<th className="text-right">Items</th>*/}
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Repairing</td>
                                    {/*<td className="text-right">0</td>*/}
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-span-2 flex flex-col gap-2 justify-start items-start">
                    <div className="card w-full">
                        <div className="card-body">
                            <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-3 mb-4">
                                <h6 className="uppercase font-light flex gap-1 items-center">
                                    <span>Repairing</span>
                                    <ForwardIcon size={18}/>
                                </h6>
                            </div>
                            <span className="uppercase">
                                Sale Price: <span className="text-green-500">AED 20.00</span>
                            </span>
                        </div>
                    </div>
                    <div className="card w-full">
                        <div className="card-body">
                            <h6 className="font-bold uppercase">Transactions</h6>
                            <div className="overflow-x-auto my-3 text-sm">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Type</th>
                                        <th>Inv/Ref#</th>
                                        <th>Name</th>
                                        <th>Date</th>
                                        <th className="text-right">Qty</th>
                                        <th className="text-right">Price/Unit</th>
                                        <th>Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>ABC</td>
                                        <td>ABC123</td>
                                        <td>Name</td>
                                        <td>26-5-2024</td>
                                        <td className="text-right">18</td>
                                        <td className="text-right">AED 0.00</td>
                                        <td>Paid</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ItemLayout>
    );
};

export default Services;