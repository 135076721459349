import React from 'react';
import Flatpickr from "react-flatpickr";
import {BookIcon, PlusCircleIcon, PrinterIcon} from "lucide-react";

const PaymentIn = () => {
    return (
        <React.Fragment>
            <div className="my-4">
                <div className="card mb-4">
                    <div className="card-body">
                        {/*<h6 className="font-bold">Quotations/Estimations</h6>*/}
                        <div className="flex flex-col md:flex-row md:justify-between md:items-start gap-3">
                            <div className="flex flex-col md:flex-row items-center justify-start gap-3 w-full">
                                <div className="flex items-center w-full">
                                    <button className="btn-secondary" disabled={true}>Between</button>
                                    {/*<Flatpickr*/}
                                    {/*    options={{*/}
                                    {/*        mode: "range",*/}
                                    {/*        dateFormat: "d M, Y",*/}
                                    {/*    }}*/}
                                    {/*    placeholder="Select Date"*/}
                                    {/*    className="form-input w-full border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"*/}
                                    {/*/>*/}
                                </div>
                                <select
                                    className="form-select border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200">
                                    <option defaultValue="true">All Firms</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <select
                                    className="form-select border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200">
                                    <option defaultValue="true">All Transactions</option>
                                    <option value="1">Sale</option>
                                    <option value="2">Purchases</option>
                                    <option value="3">Payment-In</option>
                                    <option value="3">Payment-Out</option>
                                    <option value="3">Credit Note</option>
                                    <option value="3">Debit Note</option>
                                    <option value="3">Sale Order</option>
                                    <option value="3">Purchase Order</option>
                                    <option value="3">Estimate</option>
                                    <option value="3">Delivery Note</option>
                                    <option value="3">Expenses</option>
                                    <option value="3">Party to Party (Received)</option>
                                    <option value="3">Party to Party (Paid)</option>
                                    <option value="3">Manufacture</option>
                                    <option value="3">Sale FA</option>
                                    <option value="3">Purchase FA</option>
                                    <option value="3">Sale (Cancelled)</option>
                                </select>
                            </div>
                            <div className="flex gap-3">
                                <button className="flex flex-col justify-center items-center">
                                    <BookIcon size={18}/>
                                    Export
                                </button>
                                <button className="flex flex-col justify-center items-center">
                                    <PrinterIcon size={18}/>
                                    Print
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mb-4">
                    <div className="card-body">
                        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-3 mb-4">
                            <h6 className="uppercase font-light">Transactions</h6>
                            <button className="btn-primary flex items-center gap-1">
                                <PlusCircleIcon size={18}/>
                                Add New
                            </button>
                        </div>
                        <div className="overflow-x-auto my-3 text-sm">
                            <table>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Date</th>
                                    <th>Ref No</th>
                                    <th>Party Name</th>
                                    <th>Category Name</th>
                                    <th>Type</th>
                                    <th>Total</th>
                                    <th>Received/Paid</th>
                                    <th>Balance</th>
                                    <th>Print/Share</th>
                                </tr>
                                </thead>
                                <tbody>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PaymentIn;