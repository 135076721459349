import React from 'react';
import ExpenseLayout from "./ExpenseLayout";

const ExpenseCategory = () => {
    return (
        <ExpenseLayout>
            <div className="grid grid-cols-3 gap-3 my-4">
                <div className="col-span-1 card">
                    <div className="card-body">
                        <div className="flex gap-1 justify-between items-center">
                            <h6 className="text-15 font-semibold">Expenses</h6>
                            <button className="btn-primary">Add Expenses</button>
                        </div>
                        <div className="overflow-x-auto my-3 text-sm">
                            <table className="w-full">
                                <thead className="ltr:text-left rtl:text-right">
                                <tr>
                                    <th>Category</th>
                                    <th className="text-right">Amount</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Petrol</td>
                                    <td className="text-right">4,521</td>
                                </tr>
                                <tr>
                                    <td>Rent</td>
                                    <td className="text-right">4,521</td>
                                </tr>
                                <tr>
                                    <td>Salary</td>
                                    <td className="text-right">4,521</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-span-2 flex flex-col gap-2 justify-start items-start">
                    <div className="card w-full">
                        <div className="card-body">

                            <div className="flex justify-between items-start gap-3 flex-col md:flex-row">
                                <div className="flex flex-col gap-1 justify-start items-start">
                                    <h6 className="uppercase font-light mb-4">Petrol</h6>
                                    <span>Direct Expense</span>
                                </div>
                                <div className="flex flex-col gap-1 justify-end items-end">
                                    <span>Total: AED 5.00</span>
                                    <span>Balance: AED 5.00</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card w-full">
                        <div className="card-body">
                            <div className="overflow-x-auto my-3 text-sm">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Date</th>
                                        <th>Exp No</th>
                                        <th>Party</th>
                                        <th>Payment Type</th>
                                        <th>Amount</th>
                                        <th>Balance</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ExpenseLayout>
    );
};

export default ExpenseCategory;