import React from 'react';
import {Link, useLocation} from "react-router-dom";

const ItemLayout = (props:any) => {
    const location = useLocation();

    return (
        <React.Fragment>
            <div className="my-4">
                <div className="flex flex-grow-1 justify-between gap-1">
                    <Link to="/items/list" className={`${location.pathname==='/items/list' ? 'btn-primary' : 'btn-secondary'} w-full`}>Products</Link>
                    <Link to="/items/services" className={`${location.pathname==='/items/services' ? 'btn-primary' : 'btn-secondary'} w-full`}>Services</Link>
                    <Link to="/items/categories" className={`${location.pathname==='/items/categories' ? 'btn-primary' : 'btn-secondary'} w-full`}>Category</Link>
                    <Link to="/items/units" className={`${location.pathname==='/items/units' ? 'btn-primary' : 'btn-secondary'} w-full`}>Units</Link>
                </div>
            </div>
            {props.children}
        </React.Fragment>
    );
};

export default ItemLayout;