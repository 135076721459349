import React from 'react';

const PartyList = () => {
    return (
        <React.Fragment>
            <div className="grid grid-cols-3 gap-3 my-4">
                <div className="col-span-1 card">
                    <div className="card-body">
                        <div className="flex gap-1 justify-between items-center">
                            <h6 className="text-15 font-semibold">Parties</h6>
                            <button className="btn-primary">Add New</button>
                        </div>
                        <div className="overflow-x-auto my-3 text-sm">
                            <table className="w-full">
                                <thead className="ltr:text-left rtl:text-right">
                                <tr>
                                    <th>Name</th>
                                    <th className="text-right">Amount</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Amezon</td>
                                    <td className="text-right">$4,521</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-span-2 flex flex-col gap-2 justify-start items-start">
                    <div className="card w-full">
                        <div className="card-body">
                            <h6 className="uppercase font-light mb-4">Test Party</h6>
                            <div className="flex justify-between items-start gap-3 flex-col md:flex-row">
                                <div className="flex flex-col gap-1 justify-start items-start">
                                    <span>Phone: +971544765504</span>
                                    <span>Email: suhaibzia786@gmail.com</span>
                                    <span>Credit Limit: AED 1,000.0</span>
                                </div>
                                <div className="flex flex-col gap-1 justify-end items-end">
                                    <span>Address: Test address</span>
                                    <span>TRN: ABC-123</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card w-full">
                        <div className="card-body">
                            <h6 className="font-bold uppercase">Transactions</h6>
                            <div className="overflow-x-auto my-3 text-sm">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Type</th>
                                        <th>Number</th>
                                        <th>Date</th>
                                        <th>Total</th>
                                        <th>Balance</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Receivable Opening Balance</td>
                                        <td className="text-right">12345</td>
                                        <td>25/05/2024</td>
                                        <td className="text-right">$4,521</td>
                                        <td className="text-right">$4,521</td>
                                        <td></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PartyList;