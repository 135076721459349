import React from 'react';
import ExpenseLayout from "./ExpenseLayout";

const ExpenseItemList = () => {
    return (
        <ExpenseLayout>
            <div className="grid grid-cols-3 gap-3 my-4">
                <div className="col-span-1 card">
                    <div className="card-body">
                        <div className="flex gap-1 justify-between items-center">
                            <h6 className="text-15 font-semibold">Expenses</h6>
                            <button className="btn-primary">Add Expenses</button>
                        </div>
                        <div className="overflow-x-auto my-3 text-sm">
                            <table className="w-full">
                                <thead className="ltr:text-left rtl:text-right">
                                <tr>
                                    <th>Item</th>
                                    <th className="text-right">Amount</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Petrol</td>
                                    <td className="text-right">4,521</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="card w-full col-span-2">
                    <div className="card-body">
                        <div className="overflow-x-auto my-3 text-sm">
                            <table>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Date</th>
                                    <th>Exp No</th>
                                    <th>Party</th>
                                    <th>Payment Type</th>
                                    <th>Amount</th>
                                    <th>Balance</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </ExpenseLayout>
    );
};

export default ExpenseItemList;