import React from 'react';
import {BookIcon, PlusCircleIcon, PrinterIcon, SlidersIcon} from "lucide-react";

const CashInHand = () => {
    return (
        <React.Fragment>
            <div className="my-4">
                <div className="card mb-4">
                    <div className="card-body">
                        <h5 className="font-bold">Cash In Hand: <span className="text-green-500">500.00</span></h5>
                    </div>
                </div>
                <div className="card mb-4">
                    <div className="card-body">
                        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-3 mb-4">
                            <h6 className="uppercase font-light">Transactions</h6>
                            <button className="btn-primary flex items-center gap-1">
                                <SlidersIcon size={18}/>
                                Adjust Cash
                            </button>
                        </div>
                        <div className="overflow-x-auto my-3 text-sm">
                            <table>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Type</th>
                                    <th>Name</th>
                                    <th>Date</th>
                                    <th>Amount</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CashInHand;