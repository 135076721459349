import React from 'react';
import ItemLayout from "./ItemLayout";
import {ForwardIcon, SlidersIcon} from "lucide-react";

const ItemList = () => {
    return (
        <ItemLayout>
            <div className="grid grid-cols-3 gap-3 my-4">
                <div className="col-span-1 card">
                    <div className="card-body">
                        <div className="flex gap-1 justify-between items-center">
                            <h6 className="text-15 font-semibold">Items</h6>
                            <button className="btn-primary">Add New</button>
                        </div>
                        <div className="overflow-x-auto my-3 text-sm">
                            <table className="w-full">
                                <thead className="ltr:text-left rtl:text-right">
                                <tr>
                                    <th>Name</th>
                                    <th className="text-right">Qty</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Soap</td>
                                    <td className="text-right">543</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-span-2 flex flex-col gap-2 justify-start items-start">
                    <div className="card w-full">
                        <div className="card-body">
                            <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-3 mb-4">
                                <h6 className="uppercase font-light flex gap-1 items-center">
                                    <span>Soap</span>
                                    <ForwardIcon size={18} />
                                </h6>
                                <button className="btn-primary btn-sm flex gap-1 items-center">
                                    <SlidersIcon size={18}/>
                                    <span>Adjust Item</span>
                                </button>
                            </div>
                            <div className="flex justify-between items-start gap-3 flex-col md:flex-row">
                                <div className="flex flex-col gap-1 justify-start items-start">
                                    <span>Sale Price: <span className="text-green-400">AED 20</span> (incl)</span>
                                    <span>Purchase Price: <span className="text-green-400">AED 15</span> (incl)</span>
                                </div>
                                <div className="flex flex-col gap-1 justify-end items-end">
                                    <span>Stock Quantity: <span className="text-green-400">0</span></span>
                                    <span>Stock Value: <span className="text-green-400">AED 0.00</span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card w-full">
                        <div className="card-body">
                            <h6 className="font-bold uppercase">Transactions</h6>
                            <div className="overflow-x-auto my-3 text-sm">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Type</th>
                                        <th>Inv/Ref#</th>
                                        <th>Name</th>
                                        <th>Date</th>
                                        <th>Qty</th>
                                        <th>Price/Unit</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>ABC</td>
                                        <td>123AB</td>
                                        <td>Name</td>
                                        <td>25/05/2024</td>
                                        <td className="text-right">12345</td>
                                        <td className="text-right">$4,521</td>
                                        <td>Paid</td>
                                        <td></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ItemLayout>
    );
};

export default ItemList;