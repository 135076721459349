import Home from "pages/app";
import PartyList from "pages/app/parties";

// Items
import ItemList from "pages/app/items/ItemList";
import Services from "pages/app/items/Services";
import Categories from "pages/app/items/Categories";
import Units from "pages/app/items/Units";

// Sales
import SaleInvoice from "pages/app/sale/SaleInvoice";
import Quotation from "pages/app/sale/Quotation";
import PaymentIn from "pages/app/sale/PaymentIn";
import OrderList from "pages/app/sale/sale-order/OrderList";
import OnlineOrder from "pages/app/sale/sale-order/OnlineOrder";
import DeliveryNote from "pages/app/sale/DeliveryNote";
import SaleReturn from "pages/app/sale/SaleReturn";

// Purchase
import PurchaseBills from "pages/app/purchase/PurchaseBills";
import PaymentOut from "pages/app/purchase/PaymentOut";
import PurchaseReturn from "pages/app/purchase/PurchaseReturn";
import PurchaseOrder from "pages/app/purchase/PurchaseOrder";

// Expenses
import ExpenseCategory from "pages/app/expenses/ExpenseCategory";
import ExpenseItemList from "pages/app/expenses/ExpenseItemList";

// Cash & Banks
import BankAccounts from "pages/app/cash-bank/BankAccounts";
import CashInHand from "pages/app/cash-bank/CashInHand";
import Cheque from "pages/app/cash-bank/Cheque";
import LoanAccount from "pages/app/cash-bank/LoanAccount";

// Create Password
import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/LogOut";
import Pages404 from "pages/AuthenticationInner/Pages404";
import Register from "pages/Authentication/Register";
import UserProfile from "pages/Authentication/UserProfile";
import ComingSoon from "pages/AuthenticationInner/ComingSoon";
import Offline from "pages/AuthenticationInner/Offline";
import Maintenance from "pages/AuthenticationInner/Maintenance";

interface RouteObject {
    path: string;
    component: React.ComponentType<any>; // Use React.ComponentType to specify the type of the component
    exact?: boolean;
}

const authProtectedRoutes: Array<RouteObject> = [
    // Dashboard
    {path: "/", component: Home},
    {path: "/home", component: Home},
    {path: "/parties", component: PartyList},

    //Items
    {path: "/items/list", component: ItemList},
    {path: "/items/services", component: Services},
    {path: "/items/categories", component: Categories},
    {path: "/items/units", component: Units},

    // Sale
    {path: "/sale/sale-invoices", component: SaleInvoice},
    {path: "/sale/estimation-quotation", component: Quotation},
    {path: "/sale/payment-in", component: PaymentIn},
    {path: "/sale/order/list", component: OrderList},
    {path: "/sale/order/online", component: OnlineOrder},
    {path: "/sale/delivery-note", component: DeliveryNote},
    {path: "/sale/sale-return", component: SaleReturn},

    // Purchase
    {path: "/purchase/bills", component: PurchaseBills},
    {path: "/purchase/payment-out", component: PaymentOut},
    {path: "/purchase/return", component: PurchaseReturn},
    {path: "/purchase/order", component: PurchaseOrder},

    // Expenses
    {path: "/expenses/category", component: ExpenseCategory},
    {path: "/expenses/items", component: ExpenseItemList},

    // Cash & Banks
    {path: "/cash-bank/accounts", component: BankAccounts},
    {path: "/cash-bank/cash-in-hand", component: CashInHand},
    {path: "/cash-bank/cheques", component: Cheque},
    {path: "/cash-bank/loan-accounts", component: LoanAccount},

    // profile
    {path: "/user-profile", component: UserProfile},
];

const publicRoutes = [

    // coming soon
    {path: "/pages-coming-soon", component: ComingSoon},

    // Error
    {path: "/pages-offline", component: Offline},
    {path: "/pages-404", component: Pages404},

    // Maintenance
    {path: "/pages-maintenance", component: Maintenance},


    // authentication
    {path: "/login", component: Login},
    {path: "/logout", component: Logout},
    {path: "/register", component: Register},

]

export {authProtectedRoutes, publicRoutes};
