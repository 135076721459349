import React from 'react';

const Cheque = () => {
    return (
        <React.Fragment>
            <h1>Cheque Details</h1>
        </React.Fragment>
    );
};

export default Cheque;