import React from 'react';
import {Coins, DollarSignIcon, NotepadText, Package, User2Icon} from "lucide-react";
import Alert from "../../../Common/Components/Alert";

const SaleInvoice = () => {
    return (
        <React.Fragment>
            <div className="card print:hidden my-4">
                <div className="card-body">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-3">
                        <div>
                            <h6 className="text-md">
                                Enter details to make your first Sale
                            </h6>
                            <span className="text-sm font-light m-0">
                                First sale is made in less than a minute on Vyapar
                            </span>
                            <hr className="my-5"/>
                            <div className="flex flex-col md:flex-row md:justify-between md:items-start">
                                <div className="w-full">
                                    <div className="flex items-center gap-2">
                                        <div className="bg-blue-500 rounded-full p-2">
                                            <NotepadText color="white" size={18}/>
                                        </div>
                                        <span className="text-md font-bold">Invoice Details:</span>
                                    </div>
                                    <div className="flex flex-col gap-3 justify-start mt-3">
                                        <span className="text-sm font-light">Invoice Number: 01</span>
                                        <span className="text-sm font-light">Invoice
                                            Date: {new Date().toLocaleDateString()}</span>
                                    </div>
                                </div>
                                <div className="w-full">
                                    <div className="flex items-center gap-2">
                                        <div className="bg-blue-500 rounded-full p-2">
                                            <User2Icon color="white" size={18}/>
                                        </div>
                                        <span className="text-md font-bold">Bill To:</span>
                                    </div>
                                    <div className="mt-3">
                                        <label htmlFor="invoiceID" className="inline-block mb-2 text-base font-medium">
                                            Customer Name<span className="text-sm text-red-500">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="customer_name"
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="w-full flex justify-center items-center h-14 py-2 bg-gray-100 rounded-md my-3 border border-dashed border-green-500">
                                <button className="flex gap-1 items-center text-blue-500 font-light">
                                    <Package size={18} color="blue"/>
                                    <span>Add Sample Item</span>
                                </button>
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="bg-blue-500 rounded-full p-2">
                                    <Coins color="white" size={18}/>
                                </div>
                                <span className="text-md font-bold">Invoice Calculation:</span>
                            </div>
                            <div className="flex mt-3 justify-between md:items-center md:flex-row gap-2">
                                <label htmlFor="invoiceID" className="inline-block mb-2 text-base font-medium w-full">
                                    Invoice Amount<span className="text-sm text-red-500">*</span>
                                </label>
                                <div className="relative w-full">
                                    <DollarSignIcon
                                        className="absolute size-4 ltr:left-3 rtl:right-3 top-3 text-slate-500 dark:text-zink-200"/>
                                    <input type="number" id="inputWithIcon"
                                           className="ltr:pl-10 rtl:pr-10 form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                           placeholder="Enter your email"/>
                                </div>
                            </div>
                            <div className="flex mt-3 justify-between md:items-center md:flex-row gap-2">
                                <label htmlFor="invoiceID" className="inline-block mb-2 text-base font-medium w-full">
                                    Received
                                </label>
                                <div className="relative w-full">
                                    <DollarSignIcon
                                        className="absolute size-4 ltr:left-3 rtl:right-3 top-3 text-slate-500 dark:text-zink-200"/>
                                    <input type="number" id="inputWithIcon"
                                           className="ltr:pl-10 rtl:pr-10 form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                           placeholder="Enter your email"/>
                                </div>
                            </div>
                            <hr className="my-5"/>
                            <Alert
                                className="px-4 py-3 text-sm text-green-500 border border-transparent rounded-md bg-green-50 dark:bg-green-400/20">
                                <div className="flex justify-between md:items-center md:flex-row gap-2">
                                    <div className="w-full">Balance</div>
                                    <div className="w-full text-right">AED 0.00</div>
                                </div>
                            </Alert>
                            <div className="flex justify-center mt-3">
                                <button type="button"
                                        className="text-white rounded-full btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                    Create Your First Invoice
                                </button>
                            </div>
                        </div>
                        <div className="bg-gray-100 p-4 rounded-md">
                            <div className="card">
                                <div className="card-body">
                                    <div className="flex flex-col">
                                        <span className="text-md text-center mt-5 uppercase">Tax Invoice</span>
                                        <div className="flex justify-between items-start mt-3 text-sm">
                                            <div className="flex flex-col gap-1 justify-start items-start">
                                                <span className="font-bold">Bill To</span>
                                                <span className="blur font-light">Customer Name</span>
                                            </div>
                                            <div className="flex flex-col gap-1 justify-end items-start">
                                                <span className="font-bold">Invoice Details</span>
                                                <span className="text-blue-300 font-light">Invoice No: #1</span>
                                                <span
                                                    className="font-light">Date: {new Date().toLocaleDateString()}</span>
                                            </div>
                                        </div>

                                        <div className="overflow-x-auto my-3 text-sm text-center">
                                            <table className="w-full">
                                                <thead className="bg-blue-500 text-white ltr:text-left rtl:text-right">
                                                <tr>
                                                    <th className="px-3.5 py-2.5 font-semibold border-b">#</th>
                                                    <th className="px-3.5 py-2.5 font-semibold border-b">Item name</th>
                                                    <th className="px-3.5 py-2.5 font-semibold border-b">Qty</th>
                                                    <th className="px-3.5 py-2.5 font-semibold border-b">Price/Unit</th>
                                                    <th className="px-3.5 py-2.5 font-semibold border-b">Amt</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td className="px-3.5 py-2.5 border-y border-transparent">
                                                        1
                                                    </td>
                                                    <td className="px-3.5 py-2.5 border-y border-transparent">
                                                        Texanol 10 Drums with 200KG
                                                    </td>
                                                    <td className="px-3.5 py-2.5 border-y border-transparent">
                                                        10
                                                    </td>
                                                    <td className="px-3.5 py-2.5 border-y border-transparent">
                                                        AED 250
                                                    </td>
                                                    <td className="px-3.5 py-2.5 border-y border-transparent">
                                                        AED 4,521
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="px-3.5 py-2.5 border-y border-transparent">
                                                        2
                                                    </td>
                                                    <td className="px-3.5 py-2.5 border-y border-transparent">
                                                        Texanol 10 Drums with 200KG
                                                    </td>
                                                    <td className="px-3.5 py-2.5 border-y border-transparent">
                                                        10
                                                    </td>
                                                    <td className="px-3.5 py-2.5 border-y border-transparent">
                                                        AED 250
                                                    </td>
                                                    <td className="px-3.5 py-2.5 border-y border-transparent">
                                                        AED 4,521
                                                    </td>
                                                </tr>
                                                </tbody>
                                                <tfoot className="border-t">
                                                <tr>
                                                    <td colSpan={2}>Total</td>
                                                    <td>20</td>
                                                    <td></td>
                                                    <td>AED 2,232</td>
                                                </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div className="grid grid-cols-3 mt-3 text-sm pb-5">
                                            <div className="col-span-1 flex flex-col gap-1 justify-start items-start">
                                                <span className="font-bold">Amount In Words:</span>
                                                <span className="font-light">Customer Name</span>
                                            </div>
                                            <div
                                                className="col-span-2 w-full flex flex-col gap-1 justify-end items-end">
                                                <div className="flex justify-between items-start mt-3 text-sm">
                                                    <span className="font-bold">Sub Total</span>
                                                    <span className="font-bold text-end">AED 2,323</span>
                                                </div>
                                                <div className="flex justify-between items-start mt-3 text-sm">
                                                    <span className="font-bold">Total</span>
                                                    <span className="font-bold text-end">AED 2,323</span>
                                                </div>
                                                <div className="flex justify-between items-start mt-3 text-sm">
                                                    <span className="font-bold">Balance Due</span>
                                                    <span className="font-bold text-end">AED 2,323</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SaleInvoice;