import React from 'react';
import ItemLayout from "./ItemLayout";

const Units = () => {
    return (
        <ItemLayout>
            <div className="grid grid-cols-3 gap-3 my-4">
                <div className="col-span-1 card">
                    <div className="card-body">
                        <div className="flex gap-1 justify-between items-center">
                            <h6 className="text-15 font-semibold">Units</h6>
                            <button className="btn-primary">Add New</button>
                        </div>
                        <div className="overflow-x-auto my-3 text-sm">
                            <table className="w-full">
                                <thead className="ltr:text-left rtl:text-right">
                                <tr>
                                    <th>Full Name</th>
                                    <th>Short Name</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Kilograms</td>
                                    <td>KG</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-span-2 flex flex-col gap-2 justify-start items-start">
                    <div className="card w-full">
                        <div className="card-body">
                            <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-3 mb-4">
                                <h6 className="uppercase font-light flex gap-1 items-center">
                                    <span>Kilograms</span>
                                </h6>
                                <button className="btn-primary">
                                    Add Conversion
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card w-full">
                        <div className="card-body">
                            <div className="overflow-x-auto my-3 text-sm">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Conversion</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>ABC</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ItemLayout>
    );
};

export default Units;