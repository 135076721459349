import React from 'react';
import {
    ArrowDown01Icon,
    ArrowDownIcon, ArrowUpIcon,
    Coins,
    DollarSignIcon,
    Mail, MoreVertical,
    NotepadText,
    Package, ShoppingCart,
    User2Icon, Wallet2Icon
} from "lucide-react";
import Alert from "../../Common/Components/Alert";
import {Dropdown} from "../../Common/Components/Dropdown";
import {Link} from "react-router-dom";
import {SalesMonthChart} from "../Dashboards/Ecommerce/Charts";

const Home = () => {
    return (
        <React.Fragment>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 my-4 text-sm">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:col-span-2">
                    <div className="card print:hidden md:col-span-2">
                        <div className="card-body">

                        </div>
                    </div>
                    <div className="card print:hidden">
                        <div className="card-body">
                            <div className="flex justify-between items-start">
                                <div className="flex justify-start items-start gap-1">
                                    <Wallet2Icon size={18} className="text-indigo-400"/>
                                    <div className="flex flex-col justify-start items-start gap-1">
                                        <span className="text-md font-light">Expenses</span>
                                        <div className="flex items-end">
                                            <span className="text-md font-bold">AED 605</span>
                                            <span className="text-gray-500">.00</span>
                                        </div>
                                    </div>
                                </div>
                                <Dropdown className="relative shrink-0">
                                    <Dropdown.Trigger type="button" className="flex items-center justify-center size-[30px] p-0 bg-white text-slate-500 btn hover:text-slate-500 hover:bg-slate-100 focus:text-slate-500 focus:bg-slate-100 active:text-slate-500 active:bg-slate-100 dark:bg-zink-700 dark:hover:bg-slate-500/10 dark:focus:bg-slate-500/10 dark:active:bg-slate-500/10 dropdown-toggle" id="sellingProductDropdown" data-bs-toggle="dropdown">
                                        <MoreVertical className="inline-block size-4"></MoreVertical>
                                    </Dropdown.Trigger>

                                    <Dropdown.Content placement="right-end" className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md dropdown-menu min-w-[10rem] dark:bg-zink-600" aria-labelledby="sellingProductDropdown">
                                        <li>
                                            <Link className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200" to="#!">1 Weekly</Link>
                                        </li>
                                        <li>
                                            <Link className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200" to="#!">1 Monthly</Link>
                                        </li>
                                        <li>
                                            <Link className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200" to="#!">3 Monthly</Link>
                                        </li>
                                        <li>
                                            <Link className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200" to="#!">6 Monthly</Link>
                                        </li>
                                        <li>
                                            <Link className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200" to="#!">This Yearly</Link>
                                        </li>
                                    </Dropdown.Content>
                                </Dropdown>
                            </div>
                            <SalesMonthChart chartId="salesThisMonthChart" />
                        </div>
                    </div>
                    <div className="card print:hidden">
                        <div className="card-body">
                            <div className="flex justify-start items-start gap-1">
                                <ArrowDownIcon size={18} className="text-green-400"/>
                                <div className="flex flex-col justify-start items-start gap-1">
                                    <span className="text-md font-light">You'll Receive</span>
                                    <div className="flex items-end">
                                        <span className="text-md font-bold">AED 2,995</span>
                                        <span className="text-gray-500">.00</span>
                                    </div>
                                </div>
                            </div>
                            <table className="text-sm mt-5">
                                <tbody>
                                <tr>
                                    <td>Test Party</td>
                                    <td>1995</td>
                                </tr>
                                <tr>
                                    <td>Suhaib Party</td>
                                    <td>1000</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card print:hidden">
                        <div className="card-body">
                            <div className="flex justify-start items-start gap-1">
                                <ArrowUpIcon size={18} className="text-red-400"/>
                                <div className="flex flex-col justify-start items-start gap-1">
                                    <span className="text-md font-light">You'll Pay</span>
                                    <div className="flex items-end">
                                        <span className="text-md font-bold">AED 2,995</span>
                                        <span className="text-gray-500">.00</span>
                                    </div>
                                </div>
                            </div>
                            <table className="text-sm mt-5">
                                <tbody>
                                <tr>
                                    <td>Test Party</td>
                                    <td className="text-red-400">1995</td>
                                </tr>
                                <tr>
                                    <td>Suhaib Party</td>
                                    <td className="text-red-400">1000</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card print:hidden">
                        <div className="card-body">
                            <div className="flex justify-between items-start">
                                <div className="flex justify-start items-start gap-1">
                                    <ShoppingCart size={18} className="text-blue-400"/>
                                    <div className="flex flex-col justify-start items-start gap-1">
                                        <span className="text-md font-light">Purchase</span>
                                        <div className="flex items-end">
                                            <span className="text-md font-bold">AED 15</span>
                                            <span className="text-gray-500">.00</span>
                                        </div>
                                    </div>
                                </div>
                                <Dropdown className="relative shrink-0">
                                    <Dropdown.Trigger type="button" className="flex items-center justify-center size-[30px] p-0 bg-white text-slate-500 btn hover:text-slate-500 hover:bg-slate-100 focus:text-slate-500 focus:bg-slate-100 active:text-slate-500 active:bg-slate-100 dark:bg-zink-700 dark:hover:bg-slate-500/10 dark:focus:bg-slate-500/10 dark:active:bg-slate-500/10 dropdown-toggle" id="sellingProductDropdown" data-bs-toggle="dropdown">
                                        <MoreVertical className="inline-block size-4"></MoreVertical>
                                    </Dropdown.Trigger>

                                    <Dropdown.Content placement="right-end" className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md dropdown-menu min-w-[10rem] dark:bg-zink-600" aria-labelledby="sellingProductDropdown">
                                        <li>
                                            <Link className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200" to="#!">1 Weekly</Link>
                                        </li>
                                        <li>
                                            <Link className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200" to="#!">1 Monthly</Link>
                                        </li>
                                        <li>
                                            <Link className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200" to="#!">3 Monthly</Link>
                                        </li>
                                        <li>
                                            <Link className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200" to="#!">6 Monthly</Link>
                                        </li>
                                        <li>
                                            <Link className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200" to="#!">This Yearly</Link>
                                        </li>
                                    </Dropdown.Content>
                                </Dropdown>
                            </div>
                            <table className="text-sm mt-5">
                                <tbody>
                                <tr>
                                    <td>Test Item</td>
                                    <td>1995</td>
                                </tr>
                                <tr>
                                    <td>Suhaib Item</td>
                                    <td>1000</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div>

            </div>
        </React.Fragment>
    );
};

export default Home;