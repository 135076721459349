import React from 'react';
import {Link, useLocation} from "react-router-dom";

const ExpenseLayout = (props:any) => {
    const location = useLocation();

    return (
        <React.Fragment>
            <div className="my-4">
                <div className="flex flex-grow-1 justify-between gap-1">
                    <Link to="/expenses/category" className={`${location.pathname==='/expenses/category' ? 'btn-primary' : 'btn-secondary'} w-full`}>Category</Link>
                    <Link to="/expenses/items" className={`${location.pathname==='/expenses/items' ? 'btn-primary' : 'btn-secondary'} w-full`}>Items</Link>
                </div>
            </div>
            {props.children}
        </React.Fragment>
    );
};

export default ExpenseLayout;