import React from 'react';
import Flatpickr from "react-flatpickr";
import {PlusCircleIcon} from "lucide-react";

const Quotation = () => {
    return (
        <React.Fragment>
            <div className="my-4">
                <div className="card mb-4">
                    <div className="card-body flex flex-col md:flex-row items-center justify-start gap-3">
                        <h6 className="font-bold">Quotations/Estimations</h6>
                        <div className="flex items-center">
                            <button className="btn-secondary" disabled={true}>Between</button>
                            <Flatpickr
                                options={{
                                    mode: "range",
                                    dateFormat: "d M, Y",
                                }}
                                placeholder="Select Date"
                                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                            />
                        </div>
                        <div>
                            <select
                                className="form-select border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200">
                                <option defaultValue="true">All Firms</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="card mb-4">
                    <div className="card-body">
                        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-3 mb-4">
                            <h6 className="uppercase font-light">Transactions</h6>
                            <button className="btn-primary flex items-center gap-1">
                                <PlusCircleIcon size={18}/>
                                Add Estimate
                            </button>
                        </div>
                        <div className="overflow-x-auto my-3 text-sm">
                            <table>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Date</th>
                                    <th>Reference No</th>
                                    <th>Name</th>
                                    <th>Total Amount</th>
                                    <th>Balance</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>26/05/2024</td>
                                    <td>1234</td>
                                    <td>Test Party</td>
                                    <td className="text-right">AED 35.00</td>
                                    <td className="text-right">AED 35.00</td>
                                    <td>
                                        <span className="text-red-500">Quotation Open</span>
                                    </td>
                                    <td>
                                        Convert
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Quotation;